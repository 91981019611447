<template>
  <div>
    <div class="img" />
    <div class="navbar">
      <div class="tabbar">
        <div v-for="item in contentList" :key="item.uuid" :class="{'check': article.uuid === item.uuid, 'tab': true}" @click="getTab(item.uuid)">
            {{item.title}}
        </div>
      </div>
      <div class="msgs">
        <div class="basic">
          <div class="title"><div class="blue" />{{article.title}}</div>
          <div class="main" v-html="article.content">
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有  <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import {article} from '@/api/home'
export default {
  data() {
    return {
      article: {},
      contentList: [],
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      article({page: 1, size: 100, sort: 'id,desc', sectionCode: '5', deleteFlag: '0'}).then(res => {
        this.contentList = res.content
        this.article = res.content[0]
      })
    },
    getTab(uuid) {
      console.log(uuid);
      this.contentList.map(item => {
        if (item.uuid === uuid) {
          this.article = item
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/css/ql.less");
.img {
  height: 180px;
  background: url('../../assets/img/img服务领域.png') no-repeat center;
}
.navbar {
  background: #F4F5F6;
  display: flex;
  justify-content: center;
  padding: 40px 0 80px;
  .tabbar {
    margin-right: 20px;
    .tab {
      width: 230px;
      height: 62px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: solid 1px #F4F5F6;
      padding-left: 50px;
      cursor: pointer;
    }
    .check{
      font-weight: bold;
      color: #2E4E9A;
      border-right: solid 3px #2E4E9A;
    }
  }
  .msgs {
    width: 930px;
    min-height: 706px;
    background: #FFFFFF;
    .basic {
      padding: 30px 50px;
      .title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        .blue {
          width: 4px;
          height: 16px;
          background: #2E4E9A;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .main {
        margin-top: 40px;
        user-select: text;
        /deep/ p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: Regular;
          color: #666666;
          line-height: 24px;
        }
        /deep/ a {
          color: #06c;
          text-decoration: underline;
        }
      }
    }
  }
}
.foot {
  height: 64px;
  background: #393D41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B1A9A9;
}
</style>
